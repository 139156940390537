import { __decorate } from "tslib";
import AppBtn from "@/components/shared/ui/AppBtn.vue";
import AppCreateSidenav from "@/components/shared/ui/AppCreateSidenav.vue";
import AppSelect from "@/components/shared/ui/AppSelect.vue";
import AppTextField from "@/components/shared/ui/AppTextField.vue";
import { indicatorReferencesModule } from "@/store/modules/indicator-references";
import { v4 as uuid } from "uuid";
import { Component, Emit, Prop, ProvideReactive, VModel, Vue, } from "vue-property-decorator";
let IndicatorCreate = class IndicatorCreate extends Vue {
    constructor() {
        super(...arguments);
        this.isSubmitting = false;
        this.fields = null;
        this.showIdError = false;
    }
    get title() {
        return this.editedItem
            ? `Modifier ${this.editedItem.name}`
            : "Nouvel indicateur";
    }
    get isFormValid() {
        return !!this.fields?.name;
    }
    get showDelete() {
        return !!this.editedItem;
    }
    created() {
        this.prefillForm();
    }
    saveItem() {
        if (this.isSubmitting || !this.isFormValid)
            return;
        const data = {
            ...this.fields,
            id: this.fields.id || uuid(),
        };
        indicatorReferencesModule
            .upsertIndicator(data)
            .then(() => {
            this.$emit("saved", true);
            this.showSidenav = false;
        })
            .finally(() => (this.isSubmitting = false));
    }
    prefillForm() {
        this.fields = {
            id: "",
            name: "",
            ...this.editedItem,
        };
    }
    deleteItem() {
        this.showSidenav = false;
        return this.editedItem;
    }
};
__decorate([
    VModel({ default: false })
], IndicatorCreate.prototype, "showSidenav", void 0);
__decorate([
    Prop()
], IndicatorCreate.prototype, "editedItem", void 0);
__decorate([
    ProvideReactive()
], IndicatorCreate.prototype, "title", null);
__decorate([
    ProvideReactive()
], IndicatorCreate.prototype, "isFormValid", null);
__decorate([
    ProvideReactive()
], IndicatorCreate.prototype, "showDelete", null);
__decorate([
    ProvideReactive()
], IndicatorCreate.prototype, "isSubmitting", void 0);
__decorate([
    Emit("deleteItem")
], IndicatorCreate.prototype, "deleteItem", null);
IndicatorCreate = __decorate([
    Component({
        components: {
            AppBtn,
            AppTextField,
            AppSelect,
            AppCreateSidenav,
        },
    })
], IndicatorCreate);
export default IndicatorCreate;
