import { __decorate } from "tslib";
import { NoCache } from "@/helpers/NoCache";
import { indicatorReferencesModule } from "@/store/modules/indicator-references";
import { Component, Vue, Watch } from "vue-property-decorator";
import AppModal from "../shared/ui/AppModal.vue";
import IndicatorCreate from "./indicators/IndicatorCreate.vue";
let IndicatorReferenceList = class IndicatorReferenceList extends Vue {
    constructor() {
        super(...arguments);
        this.showDeleteConfirmModal = false;
        this.showIndicatorCreate = false;
        this.indicatorToDelete = null;
        this.editedIndicator = null;
        this.showIssues = true;
    }
    get indicators() {
        return indicatorReferencesModule.indicatorReferences;
    }
    mounted() {
        indicatorReferencesModule.loadIndicators();
        this.$events.$emit("menu-change", {
            menu: "Paramètres",
            subMenu: "Indicateurs",
        });
    }
    editIndicator(indicator) {
        this.editedIndicator = indicator;
        this.showIndicatorCreate = true;
    }
    deleteIndicator(indicator) {
        this.indicatorToDelete = indicator;
        this.showDeleteConfirmModal = true;
    }
    onDeleteConfirm(confirmed) {
        if (confirmed)
            indicatorReferencesModule.deleteIndicator(this.indicatorToDelete.id);
        this.indicatorToDelete = null;
        this.showDeleteConfirmModal = false;
    }
    onShowIndicatorCreate(showIndicatorCreate) {
        if (!showIndicatorCreate)
            this.editedIndicator = null;
    }
};
__decorate([
    NoCache()
], IndicatorReferenceList.prototype, "indicators", null);
__decorate([
    Watch("showIndicatorCreate")
], IndicatorReferenceList.prototype, "onShowIndicatorCreate", null);
IndicatorReferenceList = __decorate([
    Component({
        components: {
            IndicatorCreate,
            AppModal,
        },
    })
], IndicatorReferenceList);
export default IndicatorReferenceList;
